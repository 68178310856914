import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const automationDashboardData = (token) =>{
    return dispatch =>{

        dispatch(retrieveAllAutomationDashboardDataStart());
        const requestData = {
            sid: token,
        };

        let url = '/getAutomationDashboardData';

        axios.post(url,
            requestData).then(response =>{
            dispatch(retrieveAllAutomationDashboardDataSuccess(response.data));
        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(retrieveAllAutomationDashboardDataFailed(err.response.data.message));
            }
        })

    };

};

export const retrieveAllAutomationDashboardDataStart = () => {
    return {
        type: actionTypes.CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_START
    }
};

export const retrieveAllAutomationDashboardDataFailed = (error) => {
    return {
        type: actionTypes.CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_FAILED,
        error: error
    }
};

export const retrieveAllAutomationDashboardDataSuccess = (data) => {
    return {
        type: actionTypes.CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_SUCCESS,
        data: data,
    }
};