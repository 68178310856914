import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const userSearchStart = () => {
    return {
        type: actionTypes.USER_SEARCH_START
    }
};

export const userSearch = (token,  id, filter, showDeleted) =>{
    return dispatch =>{

        dispatch(userSearchStart());
        const requestData = {
            sid: token,
            cid: id,
            searchFilter: filter,
            showDeleted: showDeleted
        };

        let url = '/getUserListJson';

        axios.post(url,
            requestData).then(response =>{
            dispatch(userSearchSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(userSearchFailed(err));
            }

        })
    };

};


export const userSearchFilter = ( data, filter) =>{
    return dispatch =>{

        const filteredData = data.filter(item => String(item.username).toLowerCase().startsWith(String(filter).toLowerCase()));
        dispatch(userSearchFilterSuccess(filteredData));
    };
};

export const userSearchFilterSuccess = (data) => {
    return {
        type: actionTypes.USER_SEARCH_FILTER_SUCCESS,
        filteredData: data,
    }
};

export const userSearchSuccess = (data) => {
    return {
        type: actionTypes.USER_SEARCH_SUCCESS,
        searchData: data,
    }
};

export const userSearchFailed = (error) => {
    return {
        type: actionTypes.USER_SEARCH_FAILED,
        error: error
    }
};

export const userSearchToggleDeleted = ( token, id, filter, showDeleted  )=>{

    return dispatch =>{
        dispatch(userSearchToggleDeletedSuccess(showDeleted));
        dispatch(userSearch(token,  id, filter, showDeleted));
    };
};

export const userSearchToggleDeletedSuccess = (showDeleted) => {
    return {
        type: actionTypes.USER_SEARCH_TOGGLE_DELETED_SUCCESS,
        showDeleted: showDeleted,
    }
};

export const userInfoStart = () => {
    return {
        type: actionTypes.USER_INFO_START
    }
};
