import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const staffSearchStart = () => {
    return {
        type: actionTypes.CORP_STAFF_SEARCH_START
    }
};

export const staffSearch = (token,  filter, showDeleted) =>{
    return dispatch =>{

        dispatch(staffSearchStart());
        const requestData = {
            sid: token,
            actionType: 'GET_CORP_STAFF',
            searchFilter: filter,
            showDeleted: showDeleted
        };

       let url = '/getCorpData';

        axios.post(url,
            requestData).then(response =>{
            dispatch(staffSearchSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(staffSearchFailed(err));
            }

        })

    };

};


export const staffSearchFilter = ( data, filter) =>{
    return dispatch =>{

        const filteredData = data.filter(item => String(item.username).toLowerCase().startsWith(String(filter).toLowerCase()));
        dispatch(staffSearchFilterSuccess(filteredData));
    };
};

export const staffSearchFilterSuccess = (data) => {
    return {
        type: actionTypes.CORP_STAFF_SEARCH_FILTER_SUCCESS,
        filteredData: data,
    }
};

export const staffSearchSuccess = (data) => {
    return {
        type: actionTypes.CORP_STAFF_SEARCH_SUCCESS,
        searchData: data,
    }
};

export const staffSearchFailed = (error) => {
    return {
        type: actionTypes.CORP_STAFF_SEARCH_FAILED,
        error: error
    }
};

export const staffSearchToggleDeleted = ( token, filter, showDeleted  )=>{

    return dispatch =>{
        dispatch(staffSearchToggleDeletedSuccess(showDeleted));
        dispatch(staffSearch(token, filter, showDeleted));
    };
};

export const staffSearchToggleDeletedSuccess = (showDeleted) => {
    return {
        type: actionTypes.CORP_STAFF_SEARCH_TOGGLE_DELETED_SUCCESS,
        showDeleted: showDeleted,
    }
};

