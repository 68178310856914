import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";

export const saveUXSearchFilters = (data) =>{
    return {
        searchFilters: data,
        type: actionTypes.UX_SAVE_SEARCH_FILTERS
    }
};

export const getUXJsonStart = () => {
    return {
        type: actionTypes.UX_JSON_START
    }
};

export const getUXJsonFailed = (error) => {
    return {
        type: actionTypes.UX_JSON_FAILED,
        error: error
    }
};

export const getUXJsonSuccess = (data) => {
    return {
        type: actionTypes.UX_JSON_SUCCESS,
        fieldControls: data,
    }
};

export const clearUXFieldControls = () =>{
    return {
        type: actionTypes.UX_JSON_CLEAR_CONTROLS
    }
};

export const getUXJson = (token, id, actionType) =>{
    return dispatch =>{

        dispatch(getUXJsonStart());

        let requestData = {};
        requestData['sid'] = token;
        requestData['id'] = id;
        requestData['actionType']=actionType;

        const url = '/getUXJSON';

        axios.post(url,
            requestData).then(response =>{
            dispatch(getUXJsonSuccess(response.data.controls ));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(getUXJsonFailed(err.response.data.message));
            }
        })
    };

};


export const updateDataStart = () => {
    return {
        type: actionTypes.UPDATE_DATA_START
    }
};

export const updateDataFailed = (error) => {
    return {
        type: actionTypes.UPDATE_DATA_FAILED,
        error: error
    }
};

export const updateDataSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_DATA_SUCCESS,
        userMessage: data.message,
        data: data.data ? data.data : null
    }
};
export const updateData = (token, data, objectId, actionType) =>{
    return dispatch =>{

        dispatch(updateDataStart());

        let requestData = {};
        requestData['sid'] = token;
        requestData['id'] = objectId;
        requestData['actionType']=actionType;

        if(data) {
            for (let key in data) {
                requestData[key] = data[key].value;
            }
        }
        const url = '/updateData';

        axios.post(url,
            requestData).then(response =>{
            dispatch(updateDataSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(updateDataFailed(err.response.data.message));
            }

        })

    };


};
