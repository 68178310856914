import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from '../../store/actions/index';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GeoGraph from '../../components/UI/Graphs/GeoGraph';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    numberValue:{
        marginTop:'auto',
        fontSize:'3em',
    },
    paper: {
        display:'flex',
        flexDirection:'column',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minHeight:'11em',
    },
    heading:{
        fontSize:'1em',
        color: theme.palette.text.primary,
    },
    subText:{
        fontSize:'0.9em',
        color: theme.palette.text.secondary,
    },

    filterIconRoot:{
        alignSelf:'flex-end',
        margin:'-10px',
        '&:hover': {
            color: '#FF0000',
            transition: '0.20s',
            transform: 'rotate(180deg)'
        }
    },

});

class Dashboard extends Component {

    componentDidMount() {
        if(this.props.token){
            this.props.onGetDashboard(this.props.token);
        }

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.dashboardData !== this.props.dashboardData || this.props.dashboardData === null) ? true : false;
    }

    redirectToReport = id =>{
        this.props.onSetReportId(id);
        this.props.history.push( '/reports');
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4} md={2} >
                        <Paper className={classes.paper} onClick={()=>{this.redirectToReport('todaysTestReport')}}>
                            <FilterListIcon fontSize='small' color='primary' classes={{root:classes.filterIconRoot}}/>
                            <Typography component="h6" className={classes.heading}>
                                Pools Tested Today
                            </Typography>
                            <Typography component="h2" className={classes.numberValue} style={{ color:'blue'}}>
                                {this.props.dashboardData? this.props.dashboardData.totalPoolsTested : 0}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2} >
                        <Paper className={classes.paper} onClick={()=>{this.redirectToReport('criticalPoolReport')}}>
                            <FilterListIcon fontSize='small' color='primary' classes={{root:classes.filterIconRoot}}/>
                            <Typography component="h6" className={classes.heading} >
                                Pools Risking Closure
                            </Typography>
                            <Typography component="h2" className={classes.numberValue} style={{ color:'#FF0000'}}>
                                {this.props.dashboardData? this.props.dashboardData.criticalPools : 0}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2} >
                        <Paper className={classes.paper}>
                            <Typography component="h6" className={classes.heading} >
                                Chemicals Out of Range
                            </Typography>
                            <Typography component="h2" className={classes.numberValue} style={{ color:'green'}}>
                                {this.props.dashboardData? this.props.dashboardData.alertedPools : 0}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={5} md={3} lg={2}>
                        <Paper className={classes.paper} onClick={()=>{this.redirectToReport('lateTestReport')}}>
                            <FilterListIcon fontSize='small' color='primary' classes={{root:classes.filterIconRoot}}/>
                            <Typography component="h6" className={classes.heading} >
                                Late Water Tests
                                <Typography className={classes.subText} >
                                    Last test older than 24 hours
                                </Typography>
                            </Typography>

                            <Typography component="h2" className={classes.numberValue}>
                                {this.props.dashboardData? this.props.dashboardData.lateTests : 0}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3} lg={2} >
                        <Paper className={classes.paper} onClick={()=>{this.redirectToReport('vgbReport')}}>
                            <FilterListIcon fontSize='small' color='primary' classes={{root:classes.filterIconRoot}}/>
                            <Typography component="h6" className={classes.heading}>
                                VGB Drains expiring
                                <Typography className={classes.subText} >
                                    in the next 90 Days
                                </Typography>
                            </Typography>

                            <Typography component="h2" className={classes.numberValue} style={{ color:'#000'}}>
                                {this.props.dashboardData? this.props.dashboardData.vgbDrainExpiring : 0}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <div style={{
                                width:'100%',
                                height:'400px',
                                margin:'1em 0 ',
                            }}>
                                <GeoGraph history={this.props.history}/>
                            </div>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        );
    }
}



const mapStateToProps = state =>{
    return {
        error: state.auth.error,
        token: state.auth.token,
        dashboardData: state.corpData.dashboardData,
    };
};

const mapDispatchToProps = dispatch => {
    return {

        onSetPoolId: (id) => dispatch( actions.companyInfoSetId( id ) ),
        onGetDashboard:(token) => dispatch( actions.dashboardData(token)),
        onSetReportId: (id) => dispatch( actions.reportSetId( id ) ),
    };
};
export default withStyles(styles, { withTheme: true })(connect( mapStateToProps, mapDispatchToProps )( Dashboard ));

