import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const templateSearchStart = () => {
    return {
        type: actionTypes.CORP_TEMPLATE_SEARCH_START
    }
};

export const templateSearch = (token) =>{
    return dispatch =>{

        dispatch(templateSearchStart());
        const requestData = {
            sid: token,
            actionType: 'GET_CORP_TEMPLATES'
        };

        let url = '/getCorpData';

        axios.post(url,
            requestData).then(response =>{
            dispatch(templateSearchSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(templateSearchFailed(err));
            }

        })

    };

};

export const templateSearchSuccess = (data) => {
    return {
        type: actionTypes.CORP_TEMPLATE_SEARCH_SUCCESS,
        searchData: data,
    }
};

export const templateSearchFailed = (error) => {
    return {
        type: actionTypes.CORP_TEMPLATE_SEARCH_FAILED,
        error: error
    }
};

export const templateSetId = (id) => {
    return {
        type: actionTypes.CORP_TEMPLATE_SET_ID,
        templateId: id,
    }
};

export const templateSetData = ( data )=>{
    return {
        type: actionTypes.CORP_TEMPLATE_SET_DATA,
        templateData: data,
    }
}