import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    searchData: null,
    filteredData:null,
    showDeleted:false,
    userData: null,
    userId:null,
    error: null,
    userMessage:null,
};



const staffSearchStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const staffSearchSuccess = (state, action) =>{
    return updateObject(state, {
        searchData: action.searchData,
        filteredData: action.searchData,
        error: null,
        loading:false
    });
};

const staffSearchFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};


const staffSearchFilterSuccess = (state, action) =>{
    return updateObject(state, {
        filteredData: action.filteredData,
        error: null,
        loading:false
    });
};

const staffSearchToggleDeletedSuccess = (state, action) =>{
    return updateObject(state, {
        showDeleted: action.showDeleted,
    });
};


const logout = (state, action) =>{
    return updateObject(state, {
        searchData: null,
        filteredData:null,
        showDeleted:false,
        userData: null,
        userId:null,
        error: null,
        userMessage:null,
    });
};

const staffReducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);

        case actionTypes.CORP_STAFF_SEARCH_START:  return staffSearchStart(state, action);
        case actionTypes.CORP_STAFF_SEARCH_SUCCESS: return staffSearchSuccess(state, action);
        case actionTypes.CORP_STAFF_SEARCH_FAILED: return staffSearchFailed(state, action);
        case actionTypes.CORP_STAFF_SEARCH_FILTER_SUCCESS: return staffSearchFilterSuccess(state,action);
        case actionTypes.CORP_STAFF_SEARCH_TOGGLE_DELETED_SUCCESS: return staffSearchToggleDeletedSuccess(state,action);
        default: return state;
    }

};

export default staffReducer;