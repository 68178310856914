import React, {Component} from 'react';
import {Geomap} from "d3plus-react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";



class GeoGraph extends Component {




    poolSelectHandler = (d) => {
        this.props.onSetPoolId(d.id);
        this.props.onSetCompanyId(d.cId);
        let toolTips = document.getElementsByClassName("d3plus-tooltip");
        Array.from(toolTips).forEach(function (element) {
            element.remove();

        });

        this.props.history.push( '/pool/info/');
    };

    render() {


        let geoGraph = null;
        let data = [];
        if(this.props.graphData ){

            data = this.props.graphData.mapData ;

            const methods = {
                 //status: 0 good, 50 -out of range 100- critical
                data: data
                ,
                pointSizeMax: 5,
                pointSizeMin: 1,

                // hides ColorScale, but still allows coloring shapes
                colorScalePosition: false,
                colorScale:"status",
                colorScaleConfig:{
                    color: ["blue",  "green",  "red"],
                },
                groupBy:["label"],
                color:{
                    value: "status"
                },

                label:(d)=> {
                    return d.label  ;
                },

                legend: false,
                point:(d)=> {
                    return [d.lon, d.lat];
                },on: {
                    "click.shape": (d) => this.poolSelectHandler(d),
                },

            };

            geoGraph = <Geomap config={methods} /> ;

        }


        return (
            geoGraph
        );
    }
}

const mapStateToProps = state =>{
    return {
        error: state.companyInfo.error,
        graphData: state.corpData.dashboardData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetPoolId: ( id ) => dispatch( actions.poolInfoSetId( id ) ),
        onSetCompanyId: (id)=> dispatch(actions.companyInfoSetId(id)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( GeoGraph );


