import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";

export const poolCheckListSetId = (id)=>{
    return {
        type: actionTypes.POOL_CHECKLIST_SET_ID,
        checklistId: id,
    }
}

export const poolChecklistDatesStart = () =>{
    return {
        type: actionTypes.POOL_CHECKLIST_DATES_START,
    }
};

export const poolChecklistDates = (token, poolId, checklistId) =>{
    return dispatch =>{

        dispatch(poolChecklistDatesStart());
        const requestData = {
            sid: token,
            pid: poolId,
            cid: checklistId
        };

        let url = '/getPoolChecklistDates';
        axios.post(url,
            requestData).then(response =>{

            dispatch(poolChecklistDatesSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolChecklistDatesFailed(err.response.data.message));
            }
        })
    };

};

export const poolChecklistDatesSuccess = (data) => {
    return {
        type: actionTypes.POOL_CHECKLIST_DATES_SUCCESS,
        poolChecklistDates: data,
    }
};

export const poolChecklistDatesFailed = (error) => {
    return {
        type: actionTypes.POOL_CHECKLIST_DATES_FAILED,
        error: error
    }
};



export const poolInfoStart = () => {
    return {
        type: actionTypes.POOL_INFO_START
    }
};

export const poolInfo = (token, id) =>{
    return dispatch =>{

        dispatch(poolInfoStart());
        const requestData = {
            sid: token,
            id: id
        };

        let url = '/getPoolInfo';
        axios.post(url,
            requestData).then(response =>{

            dispatch(poolInfoSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolInfoFailed(err.response.data.message));
            }
        })
    };

};

export const poolInfoSuccess = (data) => {
    return {
        type: actionTypes.POOL_INFO_SUCCESS,
        poolData: data,
    }
};

export const poolInfoFailed = (error) => {
    return {
        type: actionTypes.POOL_INFO_FAILED,
        error: error
    }
};

export const poolInfoSetId = (id) => {
    return {
        type: actionTypes.POOL_INFO_SET_ID,
        poolId: id,
    }
};


export const poolLog = (token,  id) =>{
    return dispatch =>{

        dispatch(poolGetLogStart());
        const requestData = {
            sid: token,
            pid: id,
        };

        let url = '/getPoolLog';

        axios.post(url,
            requestData).then(response =>{
            dispatch(poolGetLogSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolGetLogFailed(err.response.data.message));
            }
        })
    };

};

export const poolGetLogStart = () => {
    return {
        type: actionTypes.POOL_GET_LOG_START
    }
};

export const poolGetLogSuccess = (data) => {
    return {
        type: actionTypes.POOL_GET_LOG_SUCCESS,
        poolLogData: data,
    }
};

export const poolGetLogFailed = (error) => {
    return {
        type: actionTypes.POOL_GET_LOG_FAILED,
        error: error
    }
};

export const poolChemicalExport = (token,  id) =>{
    return dispatch =>{

        dispatch(poolGetChemicalExportStart());
        const requestData = {
            sid: token,
            pid: id,
        };

        let url = '/getChemicalExport';

        axios.post(url,
            requestData).then(response =>{
            dispatch(poolGetChemicalExportSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolGetChemicalExportFailed(err.response.data.message));
            }
        })
    };

};




export const poolGetChemicalExportStart = () => {
    return {
        type: actionTypes.POOL_GET_CHEMICAL_EXPORT_START
    }
};

export const poolGetChemicalExportSuccess = (data) => {
    return {
        type: actionTypes.POOL_GET_CHEMICAL_EXPORT_SUCCESS,
        poolChemicalExportData: data,
    }
};

export const poolGetChemicalExportFailed = (error) => {
    return {
        type: actionTypes.POOL_GET_CHEMICAL_EXPORT_FAILED,
        error: error
    }
};


export const addPool = (token, data, companyId) =>{
    return dispatch =>{

        dispatch(poolAddPoolStart());

        let requestData = {
            companyId: companyId,
        };
        requestData['sid'] = token;
        for ( let key in data ) {
            requestData[key] = data[key].value;
        }

        const url = '/addPool';

        axios.post(url,
            requestData).then(response =>{
            dispatch(poolAddPoolSuccess('Pool Saved Successfully'));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolAddPoolFailed(err.response.data.message));
            }

        })

    };

};

export const poolAddPoolStart = () => {
    return {
        type: actionTypes.POOL_ADD_POOL_START
    }
};

export const poolAddPoolSuccess = (data) => {
    return {
        type: actionTypes.POOL_ADD_POOL_SUCCESS,
        userMessage: data,

    }
};

export const poolAddPoolFailed = (error) => {
    return {
        type: actionTypes.POOL_ADD_POOL_FAILED,
        error: error
    }
};

export const poolResetUserMessage =() =>{
    return {
        type: actionTypes.POOL_RESET_USER_MESSAGE,
    }
};

export const poolTestFieldDefaults = (token, poolId) =>{
    return dispatch =>{

        dispatch(retrievePoolTestFieldDefaultsStart());
        const requestData = {
            sid: token,
            id: poolId,
        };


        let url = '/getDefaultCompanyPoolSettings';

        axios.post(url,
            requestData).then(response =>{

            dispatch(retrievePoolTestFieldDefaultsSuccess(response.data.testFieldDefaults));

        }).catch(err =>{

            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(retrievePoolTestFieldDefaultsFailed(err.response.data.message));
            }
        })

    };

};


export const retrievePoolTestFieldDefaultsStart = () => {
    return {
        type: actionTypes.POOL_GET_TEST_FIELD_DEFAULTS_START
    }
};

export const retrievePoolTestFieldDefaultsSuccess = (data) => {
    return {
        type: actionTypes.POOL_GET_TEST_FIELD_DEFAULTS_SUCCESS,
        defaults: data,
    }
};

export const retrievePoolTestFieldDefaultsFailed = (error) => {
    return {
        type: actionTypes.POOL_GET_TEST_FIELD_DEFAULTS_FAILED,
        error: error
    }
};

export const updatePoolTestFieldDefaults = (token, data, poolId) =>{
    return dispatch =>{

        dispatch(updatePoolTestFieldDefaultsStart());

        let requestData = {};
        requestData['sid'] = token;
        requestData['id'] = poolId;

        for ( let key in data ) {
            const fieldName = key.split("_");
            const requiredName = "Pool" === fieldName[0] ?'PoolSettingRequired['+fieldName[1]+']' : 'SpaSettingRequired['+fieldName[1]+']';
            const LockedName = "Pool" === fieldName[0] ?'PoolSettingLocked['+fieldName[1]+']' : 'SpaSettingLocked['+fieldName[1]+']';

            if(data[key].elementConfig && data[key].elementConfig.locked ==='true') {
                //check for locked status
                requestData[LockedName] = 'true';
            }
            if(data[key].elementConfig && data[key].elementConfig.required ==='true') {
                //check for locked status
                requestData[requiredName] = 'true';
            }
        }


        const url = '/updatePoolTestFieldDefaults';

        axios.post(url,
            requestData).then(response =>{
            dispatch(updatePoolTestFieldDefaultsSuccess('Default Settings Saved!'));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(updatePoolTestFieldDefaultsFailed(err.response.data.message));
            }

        })


    };


};

export const updatePoolTestFieldDefaultsStart = () => {
    return {
        type: actionTypes.POOL_UPDATE_TEST_FIELD_DEFAULTS_START,
    }
};

export const updatePoolTestFieldDefaultsFailed = (error) => {
    return {
        type: actionTypes.POOL_UPDATE_TEST_FIELD_DEFAULTS_FAILED,
        error: error
    }
};

export const updatePoolTestFieldDefaultsSuccess = (data) => {
    return {
        type: actionTypes.POOL_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS,
        userMessage: data,
    }
};


export const poolAutomationData = (token,  id) =>{
    return dispatch =>{

        dispatch(poolGetAutomationDataStart());
        const requestData = {
            sid: token,
            pid: id,
        };

        let url = '/getLatestPoolControllerInfo';

        axios.post(url,
            requestData).then(response =>{
            dispatch(poolGetAutomationDataSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolGetAutomationDataFailed(err.response.data.message));
            }
        })
    };

};

export const poolGetAutomationDataStart = () => {
    return {
        type: actionTypes.POOL_GET_AUTOMATION_DATA_START
    }
};

export const poolGetAutomationDataSuccess = (data) => {

    return {
        type: actionTypes.POOL_GET_AUTOMATION_DATA_SUCCESS,
        poolAutomationData: data,
    }
};

export const poolGetAutomationDataFailed = (error) => {
    return {
        type: actionTypes.POOL_GET_AUTOMATION_DATA_FAILED,
        error: error
    }
};