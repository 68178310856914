import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    companyData: null,
    companyId: null,
    chemicalDefaults:null,
    fieldControls:null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    userMessage: null,
    commandSuccess:false,
    enrollSuccess:false,
};

const companyInfoStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        loading:true,
        companyData: null,
        fieldControls:null,
        chemicalDefaults:null,
        userMessage:null,
        commandSuccess:false,
    });
};


const companyInfoSuccess = (state, action) =>{
    return updateObject(state, {
        companyData: action.companyData,
        error: null,
        loading:false,
        enrollSuccess:false,
    });
};

const companyInfoFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false,
        enrollSuccess:false,
    });
};

const companyInfoSetId = (state, action) =>{
    return updateObject(state, {
        companyId: action.companyId
    });
};

const companyCreateStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        loading:true,
        companyData: null,
        userMessage: null,
        enrollSuccess:false,
    });
};

const companyCreateFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false,
        enrollSuccess:false,
    });
};

const companyCreateSuccess = (state, action) =>{
    return updateObject(state, {
        userMessage: action.userMessage,
        error: null,
        loading:false,
        enrollSuccess:true,
    });
};


const retrieveChemDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        chemicalDefaults: null,
        userMessage: null,
    });
};

const retrieveChemDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
    });
};

const retrieveChemDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        chemicalDefaults: action.defaults,
        error: null,
    });
};

const updateChemDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
    });
};

const updateChemDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        userMessage:null,
    });
};

const updateChemDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        userMessage: action.userMessage,
        error: null,
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        companyData: null,
        companyId: null,
        chemicalDefaults:null,
        fieldControls:null,
        error: null,
        loading: false,
        authRedirectPath: '/',
        userMessage: null,
        commandSuccess:false,
        enrollSuccess:false,
    });
};

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);

        case actionTypes.COMPANY_INFO_START: return companyInfoStart(state, action);
        case actionTypes.COMPANY_INFO_SUCCESS: return companyInfoSuccess(state, action);
        case actionTypes.COMPANY_INFO_FAILED: return companyInfoFailed(state, action);
        case actionTypes.COMPANY_INFO_SET_ID: return companyInfoSetId(state, action);

        case actionTypes.COMPANY_CREATE_START: return companyCreateStart(state, action);
        case actionTypes.COMPANY_CREATE_SUCCESS: return companyCreateSuccess(state, action);
        case actionTypes.COMPANY_CREATE_FAILED: return companyCreateFailed(state, action);

        case actionTypes.COMPANY_GET_CHEM_DEFAULTS_START: return retrieveChemDefaultsStart(state, action);
        case actionTypes.COMPANY_GET_CHEM_DEFAULTS_SUCCESS: return retrieveChemDefaultsSuccess(state, action);
        case actionTypes.COMPANY_GET_CHEM_DEFAULTS_FAILED: return retrieveChemDefaultsFailed(state, action);

        case actionTypes.COMPANY_UPDATE_CHEM_DEFAULTS_START: return updateChemDefaultsStart(state, action);
        case actionTypes.COMPANY_UPDATE_CHEM_DEFAULTS_SUCCESS: return updateChemDefaultsSuccess(state, action);
        case actionTypes.COMPANY_UPDATE_CHEM_DEFAULTS_FAILED: return updateChemDefaultsFailed(state, action);


        default: return state;
    }

};

export default reducer;