import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const companyInfoStart = () => {
    return {
        type: actionTypes.COMPANY_INFO_START
    }
};

export const companyInfo = (token, id) =>{
    return dispatch =>{

        dispatch(companyInfoStart());
        const requestData = {
            sid: token,
            cid: id
        };


          let url = '/getCompany';

          axios.post(url,
              requestData).then(response =>{
              dispatch(companyInfoSuccess(response.data));

          }).catch(err =>{
              if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                  //we are no longer logged in/ re-authenticate.
                  dispatch(logout());
              }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                  dispatch(authFailed(err.response.data.message));
                  dispatch(logout());
              }else{
                  dispatch(companyInfoFailed(err.response.data.message));
              }
          })

    };

};

export const companyInfoSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_INFO_SUCCESS,
        companyData: data,
    }
};

export const companyInfoFailed = (error) => {
    return {
        type: actionTypes.COMPANY_INFO_FAILED,
        error: error
    }
};

export const companyInfoSetId = (id) => {
    return {
        type: actionTypes.COMPANY_INFO_SET_ID,
        companyId: id,
    }
};

export const companyCreateStart = () => {
    return {
        type: actionTypes.COMPANY_CREATE_START
    }
};

export const companyCreateFailed = (error) => {
    return {
        type: actionTypes.COMPANY_CREATE_FAILED,
        error: error
    }
};

export const companyCreateSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_CREATE_SUCCESS,
        userMessage: data,
    }
};

export const companyCreateAccount = (token, data) =>{
    return dispatch =>{

        dispatch(companyCreateStart());

        let requestData = {};
        requestData['sid'] = token;
        for ( let key in data ) {
            requestData[key] = data[key].value;
        }

        const url = '/createAccount';

        axios.post(url,
            requestData).then(response =>{
             dispatch(companyInfoSetId(response.data.id));
             dispatch(companyCreateSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(companyCreateFailed(err.response.data.message));
            }

        })
    };
};

export const retrieveChemDefaultsStart = () => {
    return {
        type: actionTypes.COMPANY_GET_CHEM_DEFAULTS_START
    }
};

export const retrieveChemDefaultsSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_GET_CHEM_DEFAULTS_SUCCESS,
        defaults: data,
    }
};

export const retrieveChemDefaultsFailed = (error) => {
    return {
        type: actionTypes.COMPANY_GET_CHEM_DEFAULTS_FAILED,
        error: error
    }
};

export const companyChemDefaults = (token, companyId) =>{
    return dispatch =>{

        dispatch(retrieveChemDefaultsStart());
        const requestData = {
            sid: token,
            id: companyId,
        };


        let url = '/getCompanyChemicalDefaults';

        axios.post(url,
            requestData).then(response =>{

            dispatch(retrieveChemDefaultsSuccess(response.data.chemDefaults));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(retrieveChemDefaultsFailed(err.response.data.message));
            }
        })

    };

};


export const updateChemDefaultsStart = () => {
    return {
        type: actionTypes.COMPANY_UPDATE_CHEM_DEFAULTS_START
    }
};

export const updateChemDefaultsFailed = (error) => {
    return {
        type: actionTypes.COMPANY_UPDATE_CHEM_DEFAULTS_FAILED,
        error: error
    }
};

export const updateChemDefaultsSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_UPDATE_CHEM_DEFAULTS_SUCCESS,
        userMessage: data,
    }
};

export const updateChemDefaults = (token, data, companyId) =>{
    return dispatch =>{

        dispatch(updateChemDefaultsStart());

        let requestData = {};
        requestData['sid'] = token;
        requestData['id'] = companyId;

        for ( let key in data ) {
            //if key contains Alert split it between low and high
            if(key.indexOf('Alert') > -1){
                const LowString = key.replace("Alert", "AlertLow");
                const highString = key.replace("Alert", "AlertHigh");

                requestData[LowString] = data[key].value[0];
                requestData[highString] = data[key].value[1];

                if(data[key].elementConfig && data[key].elementConfig.locked ==='true') {
                    //check for locked status
                    const lockedKey = 'locked_' + LowString;
                    requestData[lockedKey] = 'true';
                }
            }else{
                requestData[key] = data[key].value;
            }
        }

        const url = '/UpdateCompanyChemicalDefaults';

        axios.post(url,
            requestData).then(response =>{
            dispatch(updateChemDefaultsSuccess('Default Settings Saved!'));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(updateChemDefaultsFailed(err.response.data.message));
            }

        })

    };

};

