import React from 'react';
import {  Redirect } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import ColorizeIcon from '@material-ui/icons/Colorize';
import PoolIcon from '@material-ui/icons/Pool';
import ComputerIcon from '@material-ui/icons/Computer';
import NotesIcon from '@material-ui/icons/Notes';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import Logo from '../../components/Logo/Logo';

import ListItemLink from '../../components/Navigation/NavigationItems/ListItemsLink';


const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        backgroundColor:'rgb(0,0,160)'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        width: `calc(100% - ${drawerWidth}px)`,
        minHeight:1
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const ResponsiveDrawer = (props)=> {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const closeDrawer = () => {
        if(mobileOpen){
            setMobileOpen(!mobileOpen);
        }
    };
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const menuItems = [
        {
            title:'Dashboard',
            icon:<DashboardIcon />,
            url:'/dashboard'
        },
        {
            title:'Automation',
            icon:<ComputerIcon />,
            url:'/automation'
        },


        {
            title:'Reports',
            icon:<NotesIcon />,
            url:'/reports'
        },
        {
            title:'Company Search',
            icon:<SearchIcon />,
            url:'/company/search'
        },
        {
            title:'New Account',
            icon:<AddCircleOutlineIcon />,
            url:'/company/create'
        }
    ];

    const corpAccountItems = [
        {
            title:'Chemical Defaults',
            icon:<ColorizeIcon />,
            url:'/reseller/defaultSettings'
        },
        {
            title:'Pool Defaults',
            icon:<PoolIcon />,
            url:'/reseller/defaultPoolSettings'
        },

    ];

    const drawer = (
        <div >

            <Logo height={"8em"}/>
            { props.isLoggedIn ?
                <List>

                    {menuItems.map((item, index) => (
                        <ListItemLink key={item.title}
                                      to={item.url}
                                      primary={item.title}
                                      icon={item.icon}
                                      onClick={closeDrawer}
                        />
                    ))}
                </List>
                : <Redirect to="/auth"/> }
            <Divider />
            { props.isLoggedIn ?
                <React.Fragment>
                    { props.isAdmin ?

                        <List>
                            <ListItemLink key="staff"
                                          to="/reseller/staff"
                                          primary="Staff"
                                          icon={<PeopleIcon/>}
                                          onClick={closeDrawer}
                            />
                            <ListItem button onClick={handleClick}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText key='Settings' primary='Settings' />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {corpAccountItems.map((item, index) => (
                                        <ListItemLink key={item.title}
                                                      to={item.url}
                                                      primary={item.title}
                                                      icon={item.icon}
                                                      onClick={closeDrawer}
                                        />
                                    ))}
                                </List>
                            </Collapse>
                            <ListItemLink key="templates"
                                          to="/reseller/templates"
                                          primary="Templates"
                                          icon={<PeopleIcon/>}
                                          onClick={closeDrawer}
                            />
                        </List>
                        :null
                    }
                    <Divider />
                    <List>
                        <ListItemLink key='auth' to={"/logout"} primary='Logout' icon={<ExitToAppIcon/>}/>
                    </List>
                </React.Fragment>
                :
                <React.Fragment>
                    <Divider />
                    <List>
                        <ListItemLink key='auth' to={"/auth"} primary='Login' icon={<ExitToAppIcon/>}/>
                    </List>
                </React.Fragment>
            }


        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Box displayPrint="none">
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            </Box>
            <Box displayPrint="none">
            <nav className={classes.drawer} aria-label="Corporate Navigation Options">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            </Box>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
};



export default ResponsiveDrawer;