import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    searchData: null,
    filteredData:null,
    poolData: null,
    poolId:null,
    error: null,
    loading: false,
    authRedirectPath: '/',
};



const poolSearchStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const poolSearchSuccess = (state, action) =>{
    return updateObject(state, {
        searchData: action.searchData,
        filteredData: action.searchData,
        error: null,
        loading:false
    });
};

const poolSearchFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

const poolSearchFilterSuccess = (state, action) =>{
    return updateObject(state, {
        filteredData: action.filteredData,
        error: null,
        loading:false
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        searchData: null,
        filteredData:null,
        poolData: null,
        poolId:null,
        error: null,
        loading: false,
        authRedirectPath: '/',
    });
};


const poolReducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.POOL_SEARCH_START:  return poolSearchStart(state, action);
        case actionTypes.POOL_SEARCH_SUCCESS: return poolSearchSuccess(state, action);
        case actionTypes.POOL_SEARCH_FAILED: return poolSearchFailed(state, action);
        case actionTypes.POOL_SEARCH_FILTER_SUCCESS: return poolSearchFilterSuccess(state,action);
        default: return state;
    }

};

export default poolReducer;