import * as actionTypes from './actionTypes';
import axios from '../../axios-defaults';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, userType) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token ,
        userType: userType
    }
};

export const authFailed = (error) => {
    return {
        type: actionTypes.AUTH_FAILED,
        error: error
    }
};

export const logout = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userType');

    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const checkAuthTimeout = (expirationTime) =>{
    return dispatch => {
        setTimeout(()=>{
            dispatch(logout());
        }, expirationTime * 1000 )
    };
};



export const auth = (email, password) =>{
    return dispatch =>{
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
        };

        let url = '/login';

        axios.post(url,
            authData).then(response =>{

                //use local storage to keep us logged in until token expires even if the user
                //refreshes the browser

                const authTimeout = 7200; // 7200 = 2 hours TODO: get this from server
                const expirationDate = new Date(new Date().getTime() + (authTimeout * 1000));
                localStorage.setItem('token', response.data.sid);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userType', response.data.type);

                //set out session cookie
                //response.cookie('PHPSESSID', response.data.sid, { httpOnly: true });

                dispatch(authSuccess(response.data.sid, response.data.type));
                dispatch(checkAuthTimeout(authTimeout));

        }).catch(err =>{
            dispatch(authFailed(err.response.data.message));
        })

    };
};

export const setAuthRedirectPath = (path)=>{
    return{
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
};

export const authCheckState = () =>{
   return dispatch => {
       const token = localStorage.getItem('token');
       if(!token){
           dispatch(logout());
       }else{


           const expirationDate = new Date(localStorage.getItem('expirationDate'));

           if( expirationDate <= new Date()){
               dispatch(logout());
           }else{
               const userType = localStorage.getItem('userType');
               dispatch(authSuccess(token, userType));
               dispatch(checkAuthTimeout(( expirationDate.getTime() - new Date().getTime())  / 1000 ));
           }

       }
   }
};