import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import * as actions from './store/actions/index';

import Dashboard from './containers/Dashboard/Dashboard';
import Logout from './containers/Auth/Logout/Logout';
import ResponsiveDrawer from './hoc/Layout/ResponsiveDrawer';
import asyncComponent from './hoc/asyncComponent/asyncComponent';

import './App.css';

const asyncAuth = asyncComponent(()=>{
    return import('./containers/Auth/Auth');
});
const asyncAutomation = asyncComponent(()=>{
    return import('./containers/Automation/Automation');
});

const asyncReports = asyncComponent(()=>{
    return import('./containers/Reports/Reports');
});
const asyncCompanySearch = asyncComponent(()=>{
    return import('./containers/Company/Search/Search');
});

const asyncCompanyInfo = asyncComponent(()=>{
    return import('./containers/Company/Info/Info');
});

const asyncCompanyCreate = asyncComponent(()=>{
    return import('./containers/Company/Create/Create');
});

const asyncCompanyEdit = asyncComponent(()=>{
    return import('./containers/Company/Edit/Edit');
});

const asyncCompanyAssignTemplates = asyncComponent(()=>{
    return import('./containers/Company/Templates/AssignTemplates');
})

const asyncUserInfo = asyncComponent(()=>{
    return import('./containers/User/Info/Info');
});

const asyncUserCreate = asyncComponent(()=>{
    return import('./containers/User/Create/Create');
});

const asyncUserEdit = asyncComponent(()=>{
    return import('./containers/User/Edit/Edit');
});

const asyncPoolInfo = asyncComponent(()=>{
    return import('./containers/Pool/Info/Info');
});

const asyncPoolReportChecklists = asyncComponent(()=>{
return import('./containers/Pool/Info/CheckLists/ChecklistViewer');
});

const asyncPoolCreate = asyncComponent(()=>{
    return import('./containers/Pool/Create/Create');
});

const asyncPoolEdit = asyncComponent(()=>{
    return import('./containers/Pool/Edit/Edit');
});

const asyncPoolDefaults = asyncComponent(()=>{
    return import('./containers/Pool/Defaults/PoolDefaults');
});

const asyncPoolLog = asyncComponent(()=>{
    return import('./containers/Pool/Search/LogBook');
});

const asyncPoolCharts = asyncComponent(()=>{
    return import('./containers/Pool/Info/Graphs');
});

const asyncResellerDefaults = asyncComponent(()=>{
    return import('./containers/Corporate/DefaultSettings/DefaultSettings');
});

const asyncResellerPoolDefaults  = asyncComponent(()=>{
    return import('./containers/Corporate/DefaultSettings/PoolDefaults');
});

const asyncResellerStaff  = asyncComponent(()=>{
    return import('./containers/Corporate/Users/Search');
});

const asyncResellerStaffInfo =  asyncComponent(()=>{
    return import('./containers/Corporate/Users/Info');
});

const asyncResellerStaffEdit =  asyncComponent(()=>{
    return import('./containers/Corporate/Users/Edit');
});

const asyncResellerStaffCreate = asyncComponent(()=>{
    return import('./containers/Corporate/Users/Create');
});

const asyncResellerTemplates = asyncComponent(()=>{
    return import('./containers/Corporate/Templates/Search');
})

const asyncResellerTemplatesEdit = asyncComponent(()=>{
    return import('./containers/Corporate/Templates/Edit');
})

const asyncCompanyDefaults = asyncComponent(()=>{
    return import('./containers/Company/Defaults/CompanyDefaults');
});

class  App extends Component {

    componentDidMount() {
        this.props.onTryAutoSignup();
    }
  render() {
    return (
        <div>
          <ResponsiveDrawer title="Administration Portal" isLoggedIn={this.props.isAuthenticated} isAdmin={this.props.isAdmin}>

              <Switch>
                  <Route path="/auth"  component={asyncAuth}/>
                  <Route path="/logout" component={Logout}/>
                  <Route path="/dashboard" exact component={Dashboard}/>
                  <Route path="/automation" exact component={asyncAutomation}/>
                  <Route path="/reports" exact component={asyncReports}/>
                  <Route path="/company/defaults" exact component={asyncCompanyDefaults} />
                  <Route path="/company/search" exact component={asyncCompanySearch}/>
                  <Route path="/company/info" exact component={asyncCompanyInfo}/>
                  <Route path="/company/create" exact component={asyncCompanyCreate}/>
                  <Route path="/company/edit" exact component={asyncCompanyEdit}/>
                  <Route path="/company/assignTemplates" exact component={asyncCompanyAssignTemplates}/>
                  <Route path="/user/info" exact component={asyncUserInfo}/>
                  <Route path="/user/create" exact component={asyncUserCreate}/>
                  <Route path="/user/edit" exact component={asyncUserEdit}/>
                  <Route path="/pool/info" exact component={asyncPoolInfo}/>
                  <Route path="/pool/report-checklists" exact component={asyncPoolReportChecklists}/>
                  <Route path="/pool/logs" exact component={asyncPoolLog}/>
                  <Route path="/pool/graphs" exact component={asyncPoolCharts} />
                  <Route path="/pool/create" exact component={asyncPoolCreate} />
                  <Route path="/pool/edit" exact component={asyncPoolEdit} />
                  <Route path="/pool/defaults" exact component={asyncPoolDefaults} />
                  <Route path="/reseller/defaultSettings" exact component={asyncResellerDefaults}/>
                  <Route path="/reseller/defaultPoolSettings" exact component={asyncResellerPoolDefaults}/>
                  <Route path="/reseller/staff" exact component={asyncResellerStaff}/>
                  <Route path="/reseller/staff/info" exact component={asyncResellerStaffInfo}/>
                  <Route path="/reseller/staff/edit" exact component={asyncResellerStaffEdit}/>
                  <Route path="/reseller/staff/create" exact component={asyncResellerStaffCreate}/>
                  <Route path="/reseller/templates" exact component={asyncResellerTemplates}/>
                  <Route path="/reseller/templates/edit" exact component={asyncResellerTemplatesEdit}/>



                  <Route path="/" exact component={asyncCompanySearch}/>
                  <Redirect to="/" />
              </Switch>
          </ResponsiveDrawer>
        </div>
    );
  }
}

const mapStateToProps = state =>{
    return {
        isAuthenticated: state.auth.token !== null,
        isAdmin: state.auth.userType ==='ADMIN',
    }
};

const mapDispatchToProps = dispatch =>{
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

