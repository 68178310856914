import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    searchData: null,
    templateData: null,
    templateId:null,
    error: null,
    userMessage:null,
};

const templateSearchStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const templateSearchSuccess = (state, action) =>{
    return updateObject(state, {
        searchData: action.searchData,
        error: null,
        loading:false
    });
};

const templateSearchFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

const templateSetId = (state, action)=>{
    return updateObject(state, {
        templateId: action.templateId,
        error: null,
        loading:false
    });
}

const templateSetData = (state, action) =>{
    return updateObject(state, {
        templateData: action.templateData,
        error: null,
        loading:false
    });
}

const logout = (state, action) =>{
    return updateObject(state, {
        searchData: null,
        templateData: null,
        templateId:null,
        error: null,
        userMessage:null,
    });
};

const templateReducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.CORP_TEMPLATE_SEARCH_START:  return templateSearchStart(state, action);
        case actionTypes.CORP_TEMPLATE_SEARCH_SUCCESS: return templateSearchSuccess(state, action);
        case actionTypes.CORP_TEMPLATE_SEARCH_FAILED: return templateSearchFailed(state, action);
        case actionTypes.CORP_TEMPLATE_SET_ID: return templateSetId(state, action);
        case actionTypes.CORP_TEMPLATE_SET_DATA: return templateSetData(state, action);
        default: return state;
    }

};

export default templateReducer;