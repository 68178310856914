import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";


const initialState = {
    reportId:null,
    reportData:null,
    error: null,
    loading: false,
    authRedirectPath: '/',
};


const reportGetReportStart = (state, action) =>{
    return updateObject(state, {reportData: null, error: null, loading:true});
};


const reportGetReportSuccess = (state, action) =>{
    return updateObject(state, {
        reportData: action.reportData,
        error: null,
        loading:false
    });
};

const reportGetReportFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

export const reportSetId = (state, action ) => {
    return updateObject(state, {
        reportId: action.reportId ,
        loading:false
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        reportId:null,
        reportData:null,
        error: null,
        loading: false,
        authRedirectPath: '/',
    });
};

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.REPORT_SET_REPORT_ID: return reportSetId(state, action);
        case actionTypes.REPORT_GET_REPORT_START: return reportGetReportStart(state, action);
        case actionTypes.REPORT_GET_REPORT_FAILED: return reportGetReportFailed(state, action);
        case actionTypes.REPORT_GET_REPORT_SUCCESS: return reportGetReportSuccess(state, action);
        default: return state;
    }

};

export default reducer;