import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    searchData: null,
    filteredData:null,
    showDeleted:false,
    userData: null,
    userId:null,
    error: null,
    loading: false,
    authRedirectPath: '/',
};



const userSearchStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const userSearchSuccess = (state, action) =>{
    return updateObject(state, {
        searchData: action.searchData,
        filteredData: action.searchData,
        error: null,
        loading:false
    });
};

const userSearchFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};


const userSearchFilterSuccess = (state, action) =>{
    return updateObject(state, {
        filteredData: action.filteredData,
        error: null,
        loading:false
    });
};

const userSearchToggleDeletedSuccess = (state, action) =>{
    return updateObject(state, {
        showDeleted: action.showDeleted,
    });
};


const logout = (state, action) =>{
    return updateObject(state, {
        searchData: null,
        filteredData:null,
        showDeleted:false,
        userData: null,
        userId:null,
        error: null,
        loading: false,
        authRedirectPath: '/',
    });
};

const userReducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.USER_SEARCH_START:  return userSearchStart(state, action);
        case actionTypes.USER_SEARCH_SUCCESS: return userSearchSuccess(state, action);
        case actionTypes.USER_SEARCH_FAILED: return userSearchFailed(state, action);
        case actionTypes.USER_SEARCH_FILTER_SUCCESS: return userSearchFilterSuccess(state,action);
        case actionTypes.USER_SEARCH_TOGGLE_DELETED_SUCCESS: return userSearchToggleDeletedSuccess(state,action);
        default: return state;
    }

};

export default userReducer;