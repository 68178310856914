import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    automationDashboardData:null,
    error: null,
};


const retrieveAllAutomationDashboardDataStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
       // automationDashboardData: null,
    });
};

const retrieveAllAutomationDashboardDataFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false,
        automationDashboardData:null,
    });
};

const retrieveAllAutomationDashboardDataSuccess = (state, action) =>{
    return updateObject(state, {
        automationDashboardData: action.data,
        error: null,
        loading:false,
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        automationDashboardData:null,
        error: null,
    });
};

const reducer = (state = initialState , action ) => {
    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);

        case actionTypes.CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_START: return retrieveAllAutomationDashboardDataStart(state, action);
        case actionTypes.CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_SUCCESS: return retrieveAllAutomationDashboardDataSuccess(state, action);
        case actionTypes.CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_FAILED: return retrieveAllAutomationDashboardDataFailed(state, action);

        default: return state;
    }

};

export default reducer;