import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware,  combineReducers } from 'redux';
import thunk from 'redux-thunk';



import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import authReducer from './store/reducers/auth';
import automationReducer from './store/reducers/corporate/automation';
import corpReducer from './store/reducers/corporate/settings';
import corpSearchReducer from './store/reducers/corporate/search';
import corpTemplateReducer from './store/reducers/corporate/templates';
import companySearchReducer from './store/reducers/company/search';
import companyInfoReducer from './store/reducers/company/info';
import userSearchReducer from './store/reducers/user/search';
import userInfoReducer from './store/reducers/user/info';
import poolSearchReducer from './store/reducers/pool/search';
import poolInfoReducer from './store/reducers/pool/info';
import reportsReducer from './store/reducers/reports/reports';
import uxReducer from './store/reducers/ux/ux';



//only support redux dev tools in development
//const composeEnhancers = process.env.NODE_ENV ==='development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

//add all reduces to the root store
const rootReducer = combineReducers({
    auth: authReducer,
    automation: automationReducer,
    corpData: corpReducer,
    corpSearch: corpSearchReducer,
    corpTemplate: corpTemplateReducer,
    companySearch: companySearchReducer,
    companyInfo: companyInfoReducer,
    userSearch: userSearchReducer,
    userInfo: userInfoReducer,
    poolSearch: poolSearchReducer,
    poolInfo: poolInfoReducer,
    reports: reportsReducer,
    ux:uxReducer,
});

//const store = createStore(rootReducer,
//    composeEnhancers(applyMiddleware(thunk)
//    ));
const store = createStore(rootReducer,
    applyMiddleware(thunk)
    );


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={'/'}>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
