import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const getReports = (token, reportId) =>{
    return dispatch =>{
        dispatch(reportGetReportStart());
        const requestData = {
            sid: token,
            reportId: reportId,

        };

        const url = '/getReports';
        axios.post(url,
            requestData).then(response =>{
            dispatch(reportGetReportSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(reportGetReportFailed(err.response.data.message));
            }
        })
    };

};

export const reportSetId = (data) => {
    return {
        type: actionTypes.REPORT_SET_REPORT_ID,
        reportId: data,
    }
};

export const reportGetReportStart = () => {
    return {
        type: actionTypes.REPORT_GET_REPORT_START
    }
};

export const reportGetReportSuccess = (data) =>{
    return {
        type: actionTypes.REPORT_GET_REPORT_SUCCESS,
        reportData: data,
    }
};

export const reportGetReportFailed = (error) => {
    return {
        type: actionTypes.REPORT_GET_REPORT_FAILED,
        error: error
    }
};