import React from 'react';
import poolSharkLogo from '../../assets/images/poolShark-logo-black.svg';



const logo = React.memo(props=>(
    <div style={{height: props.height, background:'inherit', margin:'1em auto', textAlign:'center'}}>
        <img style={{height: '100%'}} src={poolSharkLogo} alt="logo" />
    </div>
));

export default logo;