import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";
import {updateObject} from "../../../shared/utility";


export const poolSearch = (token,  id, filter) =>{
    return dispatch =>{

        dispatch(poolSearchStart());
        const requestData = {
            sid: token,
            cid: id,
            searchFilter: filter,
        };


        let url = '/getPoolList';

        axios.post(url,
            requestData).then(response =>{
            dispatch(poolSearchSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(poolSearchFailed(err.response.data.message));
            }
        })
    };

};

export const poolSearchFilter = ( data, filter) =>{
    return dispatch =>{
        if(data['tableData'] ){
            const filterData = updateObject(data, {
                tableData: data['tableData'].filter(item => String(item.label).toLowerCase().startsWith(String(filter).toLowerCase()))
            });
            dispatch(poolSearchFilterSuccess(filterData));
        }else{
            dispatch(poolSearchFilterSuccess(data.filter(item => String(item.label).toLowerCase().startsWith(String(filter).toLowerCase()))));
        }
    };
};

export const poolSearchFilterSuccess = (data) => {
    return {
        type: actionTypes.POOL_SEARCH_FILTER_SUCCESS,
        filteredData: data,
    }
};

export const poolSearchStart = () => {
    return {
        type: actionTypes.POOL_SEARCH_START
    }
};

export const poolSearchSuccess = (data) => {
    return {
        type: actionTypes.POOL_SEARCH_SUCCESS,
        searchData: data,
    }
};

export const poolSearchFailed = (error) => {
    return {
        type: actionTypes.POOL_SEARCH_FAILED,
        error: error
    }
};



