import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";

export const userInfoStart = () => {
    return {
        type: actionTypes.USER_INFO_START
    }
};

export const userInfo = (token, id) =>{
    return dispatch =>{

        dispatch(userInfoStart());
        const requestData = {
            sid: token,
            id: id
        };

        let url = '/getUserInfo';
        axios.post(url,
            requestData).then(response =>{
            dispatch(userInfoSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(userInfoFailed(err));
            }

        })
    };

};

export const userInfoSuccess = (data) => {
    return {
        type: actionTypes.USER_INFO_SUCCESS,
        userData: data,
    }
};

export const userInfoFailed = (error) => {
    return {
        type: actionTypes.USER_INFO_FAILED,
        error: error
    }
};

export const userInfoSetId = (id) => {
    return {
        type: actionTypes.USER_INFO_SET_ID,
        userId: id,
    }
};


export const userPoolGroups = (token, id) =>{
    return dispatch =>{

        dispatch(userPoolGroupStart());
        const requestData = {
            sid: token,
            id: id
        };

        let url = '/getPoolGroupsForUser';
        axios.post(url,
            requestData).then(response =>{
            dispatch(userPoolGroupSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(userPoolGroupFailed(err));
            }

        })
    };

};




export const userPoolGroupStart = () => {
    return {
        type: actionTypes.USER_POOL_GROUP_START
    }
};

export const userPoolGroupSuccess = (data) => {
    return {
        type: actionTypes.USER_POOL_GROUP_SUCCESS,
        userPoolGroups: data,
    }
};

export const userPoolGroupFailed = (error) => {
    return {
        type: actionTypes.USER_POOL_GROUP_FAILED,
        error: error
    }
};