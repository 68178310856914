import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    poolData: null,
    poolChecklistData: null,
    poolCheckListId:null,
    poolChecklistReport:null,
    poolLog: null,
    poolAutomationData: null,
    poolChemicalExportData: null,
    testFieldDefaults:null,
    poolId: null,
    error: null,
    loading: false,
    saveSuccess:false,
    userMessage: null,
    authRedirectPath: '/',
};

const poolCheckListSetId = (state, action) =>{
    return updateObject(state, {
        poolCheckListId: action.checklistId,
        poolChecklistReport: null,
    });
};

const poolInfoStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true, poolData:null,
        poolChemicalExportData: null, poolAutomationData: null, poolChecklistData: null, poolCheckListId: null,
        poolChecklistReport: null,});
};


const poolInfoSuccess = (state, action) =>{
    return updateObject(state, {
        poolData: action.poolData,
        error: null,
        loading:false
    });
};

const poolInfoFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

const poolInfoSetId = (state, action) =>{
    return updateObject(state, {
        poolId: action.poolId
    });
};

const poolChecklistDatesStart = (state, action)=>{
    return updateObject(state, {error: null, loading:true, poolChecklistData:null,
        });
}

const poolChecklistDatesSuccess = (state, action) =>{
    return updateObject(state, {
        poolChecklistData: action.poolChecklistDates,
        error: null,
        loading:false
    });
};

const poolChecklistDatesFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};



const poolGetLogStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true, poolLog: null});
};


const poolGetLogSuccess = (state, action) =>{
    return updateObject(state, {
        poolLog: action.poolLogData,
        error: null,
        loading:false
    });
};

const poolGetLogFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};


const poolGetAutomationDataStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true, poolAutomationData: null});
};


const poolGetAutomationDataSuccess = (state, action) =>{
    return updateObject(state, {
        poolAutomationData: action.poolAutomationData,
        error: null,
        loading:false
    });
};

const poolGetAutomationDataFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};


const poolGetChemicalExportStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true, poolChemicalExportData: null});
};


const poolGetChemicalExportSuccess = (state, action) =>{
    return updateObject(state, {
        poolChemicalExportData: action.poolChemicalExportData,
        error: null,
        loading:false
    });
};

const poolGetChemicalExportFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};




const poolAddPoolStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        poolData: null,
        poolLog: null,
        poolId: null,
        saveSuccess:false,
        userMessage: null,
    });
};


const poolAddPoolSuccess = (state, action) =>{
    return updateObject(state, {
        poolData: null,
        error: null,
        saveSuccess: true,
        userMessage: action.userMessage,
    });
};

const poolAddPoolFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
    });
};


const poolResetUserMessage = (state, action) =>{
    return updateObject(state, {
        userMessage: null ,
    });
};


const retrievePoolTestFieldDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        testFieldDefaults: null,
        userMessage: null,
    });
};

const retrievePoolTestFieldDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
    });
};

const retrievePoolTestFieldDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        testFieldDefaults: action.defaults,
        error: null,
    });
};

const updatePoolTestFieldDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
    });
};

const updatePoolTestFieldDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        userMessage:null,
    });
};

const updatePoolTestFieldDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        userMessage: action.userMessage,
        error: null,
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        poolData: null,
        poolAutomationData: null,
        poolLog: null,
        poolChecklistData: null,
        poolCheckListId:null,
        poolChecklistReport:null,
        testFieldDefaults:null,
        poolId: null,
        error: null,
        loading: false,
        saveSuccess:false,
        userMessage: null,
        authRedirectPath: '/',
    });
};

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.POOL_INFO_START: return poolInfoStart(state, action);
        case actionTypes.POOL_INFO_SUCCESS: return poolInfoSuccess(state, action);
        case actionTypes.POOL_INFO_FAILED: return poolInfoFailed(state, action);
        case actionTypes.POOL_INFO_SET_ID: return poolInfoSetId(state, action);
        case actionTypes.POOL_GET_LOG_START:  return poolGetLogStart(state, action);
        case actionTypes.POOL_GET_LOG_SUCCESS: return poolGetLogSuccess(state, action);
        case actionTypes.POOL_GET_LOG_FAILED: return poolGetLogFailed(state, action);
        case actionTypes.POOL_GET_AUTOMATION_DATA_START: return poolGetAutomationDataStart(state, action);
        case actionTypes.POOL_GET_AUTOMATION_DATA_SUCCESS: return poolGetAutomationDataSuccess(state, action);
        case actionTypes.POOL_GET_AUTOMATION_DATA_FAILED: return poolGetAutomationDataFailed(state, action);

        case actionTypes.POOL_GET_CHEMICAL_EXPORT_START: return poolGetChemicalExportStart(state, action);
        case actionTypes.POOL_GET_CHEMICAL_EXPORT_SUCCESS: return poolGetChemicalExportSuccess(state, action);
        case actionTypes.POOL_GET_CHEMICAL_EXPORT_FAILED: return poolGetChemicalExportFailed(state, action);

        case actionTypes.POOL_ADD_POOL_START:  return poolAddPoolStart(state, action);
        case actionTypes.POOL_ADD_POOL_SUCCESS: return poolAddPoolSuccess(state, action);
        case actionTypes.POOL_ADD_POOL_FAILED: return poolAddPoolFailed(state, action);

        case actionTypes.POOL_RESET_USER_MESSAGE: return poolResetUserMessage(state, action);

        case actionTypes.POOL_GET_TEST_FIELD_DEFAULTS_START: return retrievePoolTestFieldDefaultsStart(state, action);
        case actionTypes.POOL_GET_TEST_FIELD_DEFAULTS_SUCCESS: return retrievePoolTestFieldDefaultsSuccess(state, action);
        case actionTypes.POOL_GET_TEST_FIELD_DEFAULTS_FAILED: return retrievePoolTestFieldDefaultsFailed(state, action);

        case actionTypes.POOL_UPDATE_TEST_FIELD_DEFAULTS_START: return updatePoolTestFieldDefaultsStart(state, action);
        case actionTypes.POOL_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS: return updatePoolTestFieldDefaultsSuccess(state, action);
        case actionTypes.POOL_UPDATE_TEST_FIELD_DEFAULTS_FAILED: return updatePoolTestFieldDefaultsFailed(state, action);

        case actionTypes.POOL_CHECKLIST_DATES_START: return poolChecklistDatesStart(state, action);
        case actionTypes.POOL_CHECKLIST_DATES_SUCCESS: return poolChecklistDatesSuccess(state, action);
        case actionTypes.POOL_CHECKLIST_DATES_FAILED: return poolChecklistDatesFailed(state, action);
        case actionTypes.POOL_CHECKLIST_SET_ID: return poolCheckListSetId(state, action);


        default: return state;
    }

};

export default reducer;