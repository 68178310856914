import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    chemicalDefaults:null,
    testFieldDefaults:null,
    dashboardData:null,
    error: null,
};


const retrieveCorpChemDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        loading:true,
        chemicalDefaults: null,
        userMessage: null,
    });
};

const retrieveCorpChemDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false,
    });
};

const retrieveCorpChemDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        chemicalDefaults: action.defaults,
        error: null,
        loading:false,
    });
};

const retrieveCorpTestFieldDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        loading:true,
        testFieldDefaults: null,
        userMessage: null,
    });
};

const retrieveCorpTestFieldDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false,
    });
};

const retrieveCorpTestFieldDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        testFieldDefaults: action.defaults,
        error: null,
        loading:false,
    });
};



const updateCorpChemDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
    });
};

const updateCorpChemDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        userMessage:null,
    });
};

const updateCorpChemDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        userMessage: action.userMessage,
        error: null,
    });
};



const updateTestFieldDefaultsStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
    });
};

const updateTestFieldDefaultsFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        userMessage:null,
    });
};

const updateTestFieldDefaultsSuccess = (state, action) =>{
    return updateObject(state, {
        userMessage: action.userMessage,
        error: null,
    });
};



const retrieveAllDashboardDataStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
        dashboardData: null,
    });
};

const retrieveAllDashboardDataFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false,
        dashboardData:null,
    });
};

const retrieveAllDashboardDataSuccess = (state, action) =>{
    return updateObject(state, {
        dashboardData: action.data,
        error: null,
        loading:false,
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        chemicalDefaults:null,
        testFieldDefaults:null,
        dashboardData:null,
        error: null,
    });
};


const reducer = (state = initialState , action ) => {
    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);

        case actionTypes.CORP_GET_CHEM_DEFAULTS_START: return retrieveCorpChemDefaultsStart(state, action);
        case actionTypes.CORP_GET_CHEM_DEFAULTS_SUCCESS: return retrieveCorpChemDefaultsSuccess(state, action);
        case actionTypes.CORP_GET_CHEM_DEFAULTS_FAILED: return retrieveCorpChemDefaultsFailed(state, action);

        case actionTypes.CORP_UPDATE_CHEM_DEFAULTS_START: return updateCorpChemDefaultsStart(state, action);
        case actionTypes.CORP_UPDATE_CHEM_DEFAULTS_SUCCESS: return updateCorpChemDefaultsSuccess(state, action);
        case actionTypes.CORP_UPDATE_CHEM_DEFAULTS_FAILED: return updateCorpChemDefaultsFailed(state, action);

        case actionTypes.CORP_GET_TEST_FIELD_DEFAULTS_START: return retrieveCorpTestFieldDefaultsStart(state, action);
        case actionTypes.CORP_GET_TEST_FIELD_DEFAULTS_SUCCESS: return retrieveCorpTestFieldDefaultsSuccess(state, action);
        case actionTypes.CORP_GET_TEST_FIELD_DEFAULTS_FAILED: return retrieveCorpTestFieldDefaultsFailed(state, action);

        case actionTypes.CORP_UPDATE_TEST_FIELD_DEFAULTS_START: return updateTestFieldDefaultsStart(state, action);
        case actionTypes.CORP_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS: return updateTestFieldDefaultsSuccess(state, action);
        case actionTypes.CORP_UPDATE_TEST_FIELD_DEFAULTS_FAILED: return updateTestFieldDefaultsFailed(state, action);


        case actionTypes.CORP_GET_ALL_DASHBOARD_DATA_START: return retrieveAllDashboardDataStart(state, action);
        case actionTypes.CORP_GET_ALL_DASHBOARD_DATA_SUCCESS: return retrieveAllDashboardDataSuccess(state, action);
        case actionTypes.CORP_GET_ALL_DASHBOARD_DATA_FAILED: return retrieveAllDashboardDataFailed(state, action);

        default: return state;
    }

};

export default reducer;