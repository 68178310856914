import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {authFailed, logout} from "../auth";


export const corpChemDefaults = (token) =>{
    return dispatch =>{

        dispatch(retrieveCorpChemDefaultsStart());
        const requestData = {
            sid: token,
        };


        let url = '/getCorpChemicalDefaults';

        axios.post(url,
            requestData).then(response =>{

            dispatch(retrieveCorpChemDefaultsSuccess(response.data.chemDefaults));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(retrieveCorpChemDefaultsFailed(err.response.data.message));
            }
        })

    };

};


export const corpTestFieldDefaults = (token) =>{
    return dispatch =>{

        dispatch(retrieveCorpTestFieldDefaultsStart());
        const requestData = {
            sid: token,
        };


        let url = '/getCorpTestFieldDefaults';

        axios.post(url,
            requestData).then(response =>{

            dispatch(retrieveCorpTestFieldDefaultsSuccess(response.data.testFieldDefaults));

        }).catch(err =>{

            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(retrieveCorpTestFieldDefaultsFailed(err.response.data.message));
            }
        })

    };

};



export const updateCorpChemDefaults = (token, data) =>{
    return dispatch =>{

        dispatch(updateCorpChemDefaultsStart());

        let requestData = {};
        requestData['sid'] = token;
        for ( let key in data ) {

            //if key contains Alert split it between low and high
            if(key.indexOf('Alert') > -1){
                const LowString = key.replace("Alert", "AlertLow");
                const highString = key.replace("Alert", "AlertHigh");

                requestData[LowString] = data[key].value[0];
                requestData[highString] = data[key].value[1];

                if(data[key].elementConfig && data[key].elementConfig.locked ==='true') {
                    //check for locked status
                    const lockedKey = 'locked_' + LowString;
                    requestData[lockedKey] = 'true';
                }


            }else{
                requestData[key] = data[key].value;
            }

        }


        const url = '/updateCorpChemicalDefaults';

        axios.post(url,
            requestData).then(response =>{
            dispatch(updateCorpChemDefaultsSuccess('Default Settings Saved!'));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(updateCorpChemDefaultsFailed(err.response.data.message));
            }

        })

    };

};


export const dashboardData = (token) =>{
    return dispatch =>{

        dispatch(retrieveAllDashboardDataStart());
        const requestData = {
            sid: token,
        };


        let url = '/getDashboardData';

        axios.post(url,
            requestData).then(response =>{

            dispatch(retrieveAllDashboardDataSuccess(response.data.dashboard));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(retrieveAllDashboardDataFailed(err.response.data.message));
            }
        })

    };

};

export const updateCorpChemDefaultsStart = () => {
    return {
        type: actionTypes.CORP_UPDATE_CHEM_DEFAULTS_START
    }
};

export const updateCorpChemDefaultsFailed = (error) => {
    return {
        type: actionTypes.CORP_UPDATE_CHEM_DEFAULTS_FAILED,
        error: error
    }
};

export const updateCorpChemDefaultsSuccess = (data) => {
    return {
        type: actionTypes.CORP_UPDATE_CHEM_DEFAULTS_SUCCESS,
        userMessage: data,
    }
};



export const retrieveCorpChemDefaultsStart = () => {
    return {
        type: actionTypes.CORP_GET_CHEM_DEFAULTS_START
    }
};

export const retrieveCorpChemDefaultsSuccess = (data) => {
    return {
        type: actionTypes.CORP_GET_CHEM_DEFAULTS_SUCCESS,
        defaults: data,
    }
};

export const retrieveCorpChemDefaultsFailed = (error) => {
    return {
        type: actionTypes.CORP_GET_CHEM_DEFAULTS_FAILED,
        error: error
    }
};

export const retrieveCorpTestFieldDefaultsStart = () => {
    return {
        type: actionTypes.CORP_GET_TEST_FIELD_DEFAULTS_START
    }
};

export const retrieveCorpTestFieldDefaultsSuccess = (data) => {
    return {
        type: actionTypes.CORP_GET_TEST_FIELD_DEFAULTS_SUCCESS,
        defaults: data,
    }
};

export const retrieveCorpTestFieldDefaultsFailed = (error) => {
    return {
        type: actionTypes.CORP_GET_TEST_FIELD_DEFAULTS_FAILED,
        error: error
    }
};


export const updateTestFieldDefaults = (token, data) =>{
    return dispatch =>{

        dispatch(updateTestFieldDefaultsStart());

        let requestData = {};
        requestData['sid'] = token;
        for ( let key in data ) {
            const fieldName = key.split("_");
            const requiredName = "Pool" === fieldName[0] ?'PoolSettingRequired['+fieldName[1]+']' : 'SpaSettingRequired['+fieldName[1]+']';
            const LockedName = "Pool" === fieldName[0] ?'PoolSettingLocked['+fieldName[1]+']' : 'SpaSettingLocked['+fieldName[1]+']';

            if(data[key].elementConfig && data[key].elementConfig.locked ==='true') {
                //check for locked status
                requestData[LockedName] = 'true';
            }
            if(data[key].elementConfig && data[key].elementConfig.required ==='true') {
                //check for locked status
                requestData[requiredName] = 'true';
            }
        }


        const url = '/updateCorpTestFieldDefaults';

        axios.post(url,
            requestData).then(response =>{
            dispatch(updateTestFieldDefaultsSuccess('Default Settings Saved!'));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(updateTestFieldDefaultsFailed(err.response.data.message));
            }

        })


    };


};

export const updateTestFieldDefaultsStart = () => {
    return {
        type: actionTypes.CORP_UPDATE_TEST_FIELD_DEFAULTS_START
    }
};

export const updateTestFieldDefaultsFailed = (error) => {
    return {
        type: actionTypes.CORP_UPDATE_TEST_FIELD_DEFAULTS_FAILED,
        error: error
    }
};

export const updateTestFieldDefaultsSuccess = (data) => {
    return {
        type: actionTypes.CORP_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS,
        userMessage: data,
    }
};


export const retrieveAllDashboardDataStart = () => {
    return {
        type: actionTypes.CORP_GET_ALL_DASHBOARD_DATA_START
    }
};

export const retrieveAllDashboardDataFailed = (error) => {
    return {
        type: actionTypes.CORP_GET_ALL_DASHBOARD_DATA_FAILED,
        error: error
    }
};

export const retrieveAllDashboardDataSuccess = (data) => {
    return {
        type: actionTypes.CORP_GET_ALL_DASHBOARD_DATA_SUCCESS,
        data: data,
    }
};
