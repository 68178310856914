import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    userData: null,
    userId: null,
    userPoolGroups:null,
    error: null,
    loading: false,
    authRedirectPath: '/',
};

const userInfoStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const userInfoSuccess = (state, action) =>{
    return updateObject(state, {
        userData: action.userData,
        error: null,
        loading:false
    });
};

const userInfoFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

const userInfoSetId = (state, action) =>{
    return updateObject(state, {
        userId: action.userId
    });
};

const userPoolGroupStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const userPoolGroupSuccess = (state, action) =>{
    return updateObject(state, {
        userPoolGroups: action.userPoolGroups,
        error: null,
        loading:false
    });
};

const userPoolGroupFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        userData: null,
        userId: null,
        userPoolGroups:null,
        error: null,
        loading: false,
        authRedirectPath: '/',
    });
};

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.USER_INFO_START: return userInfoStart(state, action);
        case actionTypes.USER_INFO_SUCCESS: return userInfoSuccess(state, action);
        case actionTypes.USER_INFO_FAILED: return userInfoFailed(state, action);
        case actionTypes.USER_INFO_SET_ID: return userInfoSetId(state, action);
        case actionTypes.USER_POOL_GROUP_START: return userPoolGroupStart(state, action);
        case actionTypes.USER_POOL_GROUP_SUCCESS: return userPoolGroupSuccess(state, action);
        case actionTypes.USER_POOL_GROUP_FAILED: return userPoolGroupFailed(state, action);
        default: return state;
    }

};

export default reducer;