import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";


const initialState = {
    searchData: null,
    showDeleted:false,
    companyData: null,
    filteredData:null,
    error: null,
    loading: false,
    authRedirectPath: '/',
};

const companyInfoStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const companyInfoSuccess = (state, action) =>{
    return updateObject(state, {
        companyData: action.companyData,
        error: null,
        loading:false
    });
};

const companyInfoFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};


const companySearchStart = (state, action) =>{
    return updateObject(state, {error: null, loading:true});
};


const companySearchSuccess = (state, action) =>{
    return updateObject(state, {
        searchData: action.searchData,
        filteredData: action.searchData,
        error: null,
        loading:false
    });
};

const companySearchFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        loading:false
    });
};

const companySearchFilterSuccess = (state, action) =>{
    return updateObject(state, {
        filteredData: action.filteredData,
        error: null,
        loading:false
    });
};

const companySearchToggleDeletedSuccess = (state, action) =>{
    return updateObject(state, {
        showDeleted: action.showDeleted,
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        searchData: null,
        showDeleted:false,
        companyData: null,
        filteredData:null,
        error: null,
        loading: false,
        authRedirectPath: '/',
    });
};

const reducer = (state = initialState , action ) => {


    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);

        case actionTypes.COMPANY_SEARCH_START:  return companySearchStart(state, action);
        case actionTypes.COMPANY_SEARCH_SUCCESS: return companySearchSuccess(state, action);
        case actionTypes.COMPANY_SEARCH_FAILED: return companySearchFailed(state, action);
        case actionTypes.COMPANY_SEARCH_FILTER_SUCCESS: return companySearchFilterSuccess(state,action);
        case actionTypes.COMPANY_SEARCH_TOGGLE_DELETED_SUCCESS: return companySearchToggleDeletedSuccess(state,action);

        case actionTypes.COMPANY_INFO_START: return companyInfoStart(state, action);
        case actionTypes.COMPANY_INFO_SUCCESS: return companyInfoSuccess(state, action);
        case actionTypes.COMPANY_INFO_FAILED: return companyInfoFailed(state, action);
        default: return state;
    }

};

export default reducer;